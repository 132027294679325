import { template as template_840f2d6f469c45219b648d5542725d87 } from "@ember/template-compiler";
const FKText = template_840f2d6f469c45219b648d5542725d87(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
